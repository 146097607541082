
.voucher-code{
    border: 2px solid #ff671b;
    border-radius: 7px;
    margin: auto;
    width: max-content;
    padding: 5px 5px;
    color: #ff671b;
    font-weight: bold;
    background: #ff671b33;
}